<template>
  <div class="table-users">
    <div class="overflow-auto">
      <b-table
        id="my-table"
        hover
        selectable
        stacked="md"
        select-mode="single"
        responsive
        :fields="fields"
        :items="items"
        :per-page="pagination"
        :current-page="currentPage"
        @row-clicked="onRowClicked"
        :busy="loading"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="spinner-text">Loading history...</strong>
          </div>
        </template>
        <template v-slot:cell(created_at)="row">
          <span :key="row.label">
            {{ items[row.index].created_at | date }}
          </span>
        </template>
        <template v-slot:cell(last_modified)="row">
          <span :key="row.label">
            {{ items[row.index].last_modified | date }}
          </span>
        </template>
      </b-table>
      <div class="pagination-users">
        <p class="pagination-users__p">
          Page {{ currentPage }} of {{ totalPages }}
        </p>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
          size="sm"
          align="right"
          @change="paginationHistory"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>
<script>
/* import LoadingSpinner from "./LoadingSpinner.vue"; */
export default {
  components: {
    /* LoadingSpinner */
  },
  props: {
    items: {
      type: Array
      /* default: [] */
    },
    fields: {
      type: Array
      /* default: [] */
    },
    rows: {
      type: Number,
      default: null
    },
    pagination: {
      type: Number,
      default: 10
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      totalPages: "",
      spinner: true
    };
  },
  watch: {
    rows() {
      this.pagesTotal();
    }
  },
  methods: {
    paginationHistory(currentPage) {
      if (this.pagination === 0) {
        let skip = (currentPage - 1) * this.perPage;
        this.$emit("callHistory", skip);
      }
    },
    pagesTotal() {
      this.totalPages = this.rows / this.perPage;
      if (this.totalPages % 1 === 0) {
        return this.totalPages;
      } else {
        this.totalPages = Math.trunc(this.totalPages) + 1;
      }
    },
    onRowClicked(userData) {
      this.$emit("userData", userData);
      userData.startDate = "";
      userData.endDate = "";
      userData.startTime = "";
      userData.endTime = "";
      userData.optionSelected = [];
      this.$store.commit("emailDetails/clickGeneralTable", userData);
    }
  },
  created() {
    this.pagesTotal();
  }
};
</script>
<style scoped lang="scss">
.overflow-auto {
  border-radius: 3px;
}
::v-deep .table > thead {
  background-color: #ebebeb;
  color: #00557e;
  font-weight: bold;
}
::v-deep .align-middle {
  color: #00557e;
}
::v-deep .table {
  color: #666666;
  background: #f8f8f8;
}
.spinner-text {
  color: #00557e;
}
.pagination-users {
  margin: 10px;
  display: flex;
  align-items: flex-end;
  justify-content: space-evenly;
  &__p {
    color: #666666;
  }
}
::v-deep .page-link {
  background: var(--app-font-color);
  color: #ffffff;
}
::v-deep .page-item.active .page-link {
  background: var(--app-font-color);
  color: white;
  border: 1px solid #00557e;
}
</style>
