<template>
  <div class="admin-panel-users">
    <div class="admin-panel-users-header">
      <AdminNav />
      <div class="select-update">
        <span>
          <font-awesome-icon
            class="link-icon select-update__icon"
            icon="sync"
          />
        </span>
        <select @change="selectUpdate($event)" class="select-update__select">
          <option
            :key="index"
            :value="option.value"
            v-for="(option, index) in optionsAdmin.optionsUpdate"
          >
            {{ option.text }}
          </option>
        </select>
      </div>
    </div>
    <div class="wrapper">
      <div class="pane left">
        <SearchUsers
          :updateSelected="updateSelect"
          @searchUsers="searchUsers"
        />
        <div class="filter">
          <div class="filter-place">
            <span>
              <font-awesome-icon
                class="link-icon filter-place__icon"
                icon="filter"
              />
            </span>
            <select @change="selectQueue($event)" class="filter-place__select">
              <option
                :key="index"
                :value="option.value"
                v-for="(option, index) in optionsAdmin.optionsQueue"
              >
                {{ option.text }}
              </option>
            </select>
          </div>
          <div class="select-organization">
            <SelectOrganization @infoOrganization="organizationSelected" />
          </div>
          <div class="quotes-datepicker">
            <div>
              <label class="quotes-datepicker__title" for="startDate"
                >Start date</label
              >
              <input
                class="quotes-datepicker__input"
                type="date"
                v-model="filterInfo.startDate"
                id="startDate"
                name="trip-start"
                required
              />
            </div>
            <div>
              <label class="quotes-datepicker__title" for="endDate"
                >End date</label
              >
              <input
                class="quotes-datepicker__input"
                type="date"
                v-model="filterInfo.endDate"
                id="endDate"
                name="trip-start"
                required
              />
            </div>
          </div>
          <div class="quotes-option" v-if="filterInfo.organization_id == 3">
            <div class="quotes-option-filter">
              <span>
                <font-awesome-icon
                  class="link-icon quotes-option-filter__icon"
                  icon="filter"
                />
              </span>
              <select
                @change="reportUserSelected($event)"
                class="quotes-option-filter__select"
              >
                <option value="0">Select an option</option>
                <option value="1">Report quote for client</option>
                <option value="2">Report quote for autoquote</option>
                <option value="3">Report quote avg time per quote</option>
              </select>
            </div>
            <div v-if="selectedReport == 1" class="quotes-option-filter">
              <span>
                <font-awesome-icon
                  class="link-icon quotes-option-filter__icon"
                  icon="filter"
                />
              </span>
              <select
                @change="selectClient($event)"
                class="quotes-option-filter__select"
              >
                <option value="0">All</option>
                <option value="1">D&R</option>
                <option value="2">GLT</option>
              </select>
            </div>
          </div>
          <div v-if="filterInfo.organization_id != 3" class="quotes-datepicker">
            <div>
              <label class="quotes-datepicker__title" for="endDate"
                >Start Time</label
              >
              <input
                class="quotes-datepicker__input"
                type="time"
                v-model="filterInfo.startTime"
                id="startTime"
                name="trip-start"
                required
              />
            </div>
            <div>
              <label class="quotes-datepicker__title" for="endDate"
                >End Time</label
              >
              <input
                class="quotes-datepicker__input"
                type="time"
                v-model="filterInfo.endTime"
                id="endTime"
                name="trip-start"
                required
              />
            </div>
          </div>
        </div>
        <LoadingSpinner
          class="left-spinner"
          text="Loading Users..."
          v-if="!itemsGeneral"
        />
        <div class="filter-options-client" v-else>
          <div
            class="filter-options-client__avg-time-per-quote"
            v-if="selectedReport == 3"
          >
            <div class="filter-options-client__custom-id">
              <b-input
                placeholder="Enter customer id"
                @keyup.enter="reportQuoteAvgTimePerQuote()"
                class="w-50 mr-3"
                type="text"
                v-model="customerId"
                autocomplete="off"
              />
              <span
                v-if="reportAvgTimePerQuote == -1 && showText"
                class="not-found"
                >Customer id not found.</span
              >
              <b-overlay
                :show="searching"
                rounded
                opacity="0.6"
                spinner-small
                bg-color="#ebecec"
                class="d-inline-block"
              >
                <OptionButton
                  title="search"
                  @click.native="reportQuoteAvgTimePerQuote()"
                />
              </b-overlay>
            </div>
            <p
              class="position-absolute w-100"
              v-if="reportAvgTimePerQuote !== -1 && showText"
            >
              The average time of the {{ customerId }} quote is:
              {{ reportAvgTimePerQuote }}
            </p>
          </div>
          <TableUsers
            v-if="!changeOrganizationTable && selectedReport != 3"
            :fields="optionsAdmin.fieldsGeneral"
            :pagination="10"
            :items="itemsGeneral"
            :rows="totalRowsGeneral"
            @userData="dataUserSelected"
            class="table-general"
          />
          <b-overlay
            :show="searchingTableInfo"
            rounded
            opacity="0.6"
            spinner-small
            bg-color="#ebecec"
            class="info-table-spinner"
          >
            <TableUsers
              v-if="changeOrganizationTable && selectedReport != 3"
              :fields="fieldsReport"
              :pagination="10"
              :items="itemsGeneral"
              :rows="totalRowsGeneral"
              class="table-general"
            />
          </b-overlay>
        </div>
      </div>
      <div class="pane right">
        <div class="gutter">
          <div class="gutter__block"></div>
        </div>
        <h5 v-if="itemsSpecific" class="user-name">
          {{ userName | uppercase }}
        </h5>
        <div v-show="specificGraphic">
          <canvas
            class="graphic"
            id="graphic"
            width="700"
            height="300"
          ></canvas>
        </div>
        <div v-show="!specificGraphic">
          <canvas
            class="graphicsSent"
            id="graphicsSent"
            width="700"
            height="300"
          ></canvas>
        </div>
        <div>
          <b-button
            variant="info"
            @click="showGraphics(false)"
            v-if="specificGraphic"
            >Graphics Sent</b-button
          >
          <b-button
            variant="outline-info"
            @click="showGraphics(true)"
            v-if="!specificGraphic"
            >General graphic</b-button
          >
        </div>
        <LoadingSpinner
          class="right-spinner"
          text="Loading User..."
          v-if="!itemsSpecific"
        />
        <div v-else>
          <FilterOptions
            @filterData="filterSpecificTable"
            :changeUser="changeUser"
            :updateSelected="updateSelect"
          />
          <TableUsers
            v-if="changeTable == false"
            :fields="optionsAdmin.fieldsSpecific"
            :items="itemsSpecific"
            :rows="totalRowsSpecific"
            responsive="true"
            class="table-specific"
            :pagination="10"
          />
          <TableUsers
            v-if="changeTable == true"
            :fields="optionsAdmin.fieldsHistory"
            :items="itemsHistory"
            :rows="totalRowsHistory"
            responsive="true"
            class="table-specific"
            :pagination="0"
            @callHistory="getUserHistory"
            :loading="loadingData"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TableUsers from "../components/TableUsers.vue";
import SearchUsers from "../components/SearchUsers.vue";
import FilterOptions from "../components/FilterOptions.vue";
import * as echarts from "echarts";
import AdminNav from "../components/AdminNav.vue";
import { mapState } from "vuex";
import LoadingSpinner from "../components/LoadingSpinner.vue";
import optionsAdmin from "../utils/adminPanelOptions";
import dayjs from "dayjs";
import SelectOrganization from "../components/SelectOrganizations.vue";
import OptionButton from "@/components/OptionButton";

export default {
  components: {
    TableUsers,
    SearchUsers,
    FilterOptions,
    AdminNav,
    LoadingSpinner,
    SelectOrganization,
    OptionButton
  },
  data() {
    return {
      specificGraphic: true,
      optionsAdmin: optionsAdmin,
      setInterval: null,
      itemsGeneral: null,
      totalRowsGeneral: null,
      userIdselected: null,
      changeUser: false,
      itemsHistory: null,
      totalRowsHistory: null,
      filterInfo: {
        startDate: "",
        endDate: "",
        statusOptions: [],
        organization_id: "",
        startTime: "",
        endTime: ""
      },
      startDateSpecific: "",
      endDateSpecific: "",
      startTimeSpecific: "",
      endTimeSpecific: "",
      optionSelectedSpecific: null,
      optionStatusHistory: null,
      values: ["6", "7", "8", "10"],
      userName: "",
      itemsSpecific: null,
      totalRowsSpecific: null,
      changeTable: false,
      changeOrganizationTable: false,
      updateSelect: 0,
      dataGraphic: [0, 0, 0, 0, 0],
      dataGraphicSend: [0, 0, 0, 0],
      loadingData: false,
      optionsCustomer: [{ value: null, text: "Select an organization" }],
      clientSelect: null,
      fieldsReport: null,
      selectedReport: null,
      customerId: null,
      reportAvgTimePerQuote: null,
      showText: false,
      searching: false,
      searchingTableInfo: false
    };
  },
  watch: {
    generalUsersInfo(data) {
      this.itemsGeneral = data.users;
      this.totalRowsGeneral = data.total;
    },
    generalUsersQuotesInfo(data) {
      this.itemsSpecific = data.quotes;
      this.totalRowsSpecific = data.quotes.length;
      this.dataGraphic = [
        data.total_quotes,
        data.total_incomplete,
        data.total_sent.total_emails,
        data.total_webhook_faileds,
        data.total_non_ltl
      ];
      this.dataGraphicSend = [
        data.total_sent.total_dispatch,
        data.total_sent.total_hauls,
        data.total_sent.total_quotes,
        data.total_sent.total_tracking
      ];
      this.createGraphic();
    },
    filterInfo: {
      deep: true,
      handler() {
        if (this.clientSelect === 1 || this.clientSelect === 2) {
          this.reportQuoteByClient();
        } else if (this.selectedReport === 2) {
          this.reportQuoteForAutoquote();
        } else if (this.clientSelect === 0 || this.selectedReport === 1) {
          this.reportGeneralForClient();
        } else {
          this.$store.commit(
            "emailDetails/filterGeneralTable",
            this.filterInfo
          );
        }
      }
    },
    customerId() {
      if (this.customerId === "" || this.customerId === null) {
        setTimeout(
          function() {
            this.showText = false;
          }.bind(this),
          1
        );
      }
    }
  },
  computed: {
    ...mapState({
      generalUsersInfo: (state) => state.emailDetails.infoGeneralUsers,
      generalUsersQuotesInfo: (state) =>
        state.emailDetails.infoGeneralUsersQuotes
    })
  },
  created() {
    this.$store.dispatch("emailDetails/generalInfoUsersSocket");
  },
  mounted() {
    this.handleMyProcessesSplitter();
    this.createGraphic();
  },
  methods: {
    organizationSelected(value) {
      this.filterInfo.organization_id = value;
      if (this.filterInfo.organization_id === 3) {
        this.changeOrganizationTable = true;
        this.reportQuoteByClient();
      } else {
        this.changeOrganizationTable = false;
      }
    },
    showGraphics(value) {
      this.specificGraphic = value;
      !value ? this.createGraphicSend() : "";
    },
    selectUpdate(event) {
      this.updateSelect = event.target.value;
    },
    selectQueue(event) {
      let queueSelectLeft = event.target.value;
      if (queueSelectLeft === "All") {
        this.filterInfo.statusOptions = this.values;
      } else {
        this.filterInfo.statusOptions = [queueSelectLeft];
      }
    },
    searchUsers(data) {
      data.startDate = this.filterInfo.startDate;
      data.endDate = this.filterInfo.endDate;
      data.startTime = this.filterInfo.startTime;
      data.endTime = this.filterInfo.endTime;
      data.statusOptions = this.filterInfo.statusOptions;
      data.organization_id = this.filterInfo.organization_id;
      this.$store.commit("emailDetails/filterGeneralTable", data);
    },
    filterSpecificTable(data) {
      this.startDateSpecific = data.startDate;
      this.endDateSpecific = data.endDate;
      this.startTimeSpecific = data.startTime;
      this.endTimeSpecific = data.endTime;
      this.optionSelectedSpecific = data.optionSelected;
      this.optionStatusHistory = data.optionStatusHistory;
      if (this.userIdselected != null) {
        data.id = this.userIdselected;
        this.$store.commit("emailDetails/clickGeneralTable", data);
      }
      if (data.optionSelectReport === "1") {
        this.changeTable = true;
        this.getUserHistory(0);
      } else {
        this.changeTable = false;
      }
    },
    dataUserSelected(userData) {
      this.changeUser === true
        ? (this.changeUser = false)
        : (this.changeUser = true);
      this.userIdselected = userData.id;
      this.userName = userData.username;
      this.showGraphics(true);
    },
    async getUserHistory(skip) {
      this.itemsHistory = [];
      this.loadingData = true;
      let params = {};
      let currentDay = dayjs(new Date()).format("YYYY-MM-DD");
      params.skip = skip;
      params.limit = 10;
      params.user_id = this.userIdselected;
      params.order_by = "-created_at";
      this.optionStatusHistory === ""
        ? (params.status_code_id = null)
        : (params.status_code_id = this.optionStatusHistory);
      if (
        this.startDateSpecific === "" &&
        this.endDateSpecific === "" &&
        this.startTimeSpecific === "" &&
        this.endTimeSpecific === ""
      ) {
        params.initial_date = `${currentDay}T00:00:00`;
        params.final_date = `${currentDay}T23:59:59.999999`;
      } else if (
        this.startDateSpecific !== "" &&
        this.endDateSpecific !== "" &&
        this.startTimeSpecific !== "" &&
        this.endTimeSpecific !== ""
      ) {
        params.initial_date = `${this.startDateSpecific}T${this.startTimeSpecific}:00-05:00`;
        params.final_date = `${this.endDateSpecific}T${this.endTimeSpecific}:00-05:00`;
      } else if (this.startDateSpecific !== "" && this.endDateSpecific !== "") {
        params.initial_date = `${this.startDateSpecific}T00:00:00`;
        params.final_date = `${this.endDateSpecific}T23:59:59.999999`;
      }
      this.userHistoryEndpoint(params);
    },
    async userHistoryEndpoint(params) {
      let infoUserHistory = await this.$store.dispatch(
        "userHistory/getUserHistory",
        params
      );
      this.loadingData = false;
      this.formatDescriptionHistory(infoUserHistory);
      this.totalRowsHistory = infoUserHistory.total;
    },
    formatDescriptionHistory(historyData) {
      let dateRegex = /\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}.\d*/;
      for (let i in historyData.history) {
        let description = historyData.history[i].description;
        let dateDetected = this.convertUCTDataToLocalDate(
          new Date(dateRegex.exec(description))
        );
        let newDescription = description.replace(
          dateRegex,
          dateDetected.toLocaleString()
        );
        historyData.history[i].description = newDescription;
      }
      this.itemsHistory = historyData.history;
    },
    convertUCTDataToLocalDate(date) {
      let newDate = new Date(
        date.getTime() + date.getTimezoneOffset() * 60 * 1000
      );

      let offset = date.getTimezoneOffset() / 60;
      let hours = date.getHours();

      newDate.setHours(hours - offset);

      return newDate;
    },
    createGraphic() {
      var chartDom = document.getElementById("graphic");
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        xAxis: {
          type: "category",
          splitLine: { show: false },
          data: ["Quotes", "Incomplete", "Send", "Error", "Non-Ltl"]
        },
        yAxis: {
          type: "value"
        },
        series: [
          {
            data: this.dataGraphic,
            type: "bar",
            stack: "Total",
            label: {
              show: true,
              position: "inside"
            }
          }
        ]
      };

      option && myChart.setOption(option);
    },
    createGraphicSend() {
      var chartDom = document.getElementById("graphicsSent");
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        xAxis: {
          type: "category",
          data: ["Dispatch", "Hauls", "Quotes", "Tracking"]
        },
        yAxis: {
          type: "value"
        },
        series: [
          {
            data: this.dataGraphicSend,
            type: "bar",
            stack: "Total",
            label: {
              show: true,
              position: "inside"
            }
          }
        ]
      };

      option && myChart.setOption(option);
    },
    handleMyProcessesSplitter() {
      const leftPane = document.querySelector(".left");
      const rightPane = document.querySelector(".right");
      const gutter = document.querySelector(".gutter");

      function resizer(e) {
        window.addEventListener("mousemove", mousemove);
        window.addEventListener("mouseup", mouseup);
        let prevX = e.x;
        const leftPanel = leftPane.getBoundingClientRect();
        const rightPanel = rightPane.getBoundingClientRect();
        function mousemove(e) {
          let newX = prevX - e.x;
          rightPane.style.width = rightPanel.width + newX + "px";
          leftPane.style.width = leftPanel.width - newX + "px";
        }
        function mouseup() {
          window.removeEventListener("mousemove", mousemove);
          window.removeEventListener("mouseup", mouseup);
        }
      }
      gutter.addEventListener("mousedown", resizer);
    },
    reportUserSelected(event) {
      this.selectedReport = event.target.value;
      if (this.selectedReport === 2) {
        this.reportQuoteForAutoquote();
      } else if (this.selectedReport === 1) {
        this.reportGeneralForClient();
      }
    },
    selectClient(event) {
      this.clientSelect = event.target.value;
      if (this.clientSelect === 1 || this.clientSelect === 2) {
        this.reportQuoteByClient();
      } else {
        this.reportGeneralForClient();
      }
    },
    async reportQuoteByClient() {
      if (this.filterInfo.startDate !== "" && this.filterInfo.endDate !== "") {
        if (this.clientSelect && this.clientSelect !== 0) {
          this.searchingTableInfo = true;
          let info = {};
          info.client_id = this.clientSelect;
          info.initial_date = this.filterInfo.startDate;
          info.final_date = this.filterInfo.endDate;
          let response = await this.$store.dispatch(
            "adminPanelGeneral/getReportByClient",
            info
          );
          if (response) {
            this.itemsGeneral = response;
            this.totalRowsGeneral = response.length;
            this.fieldsReport = this.optionsAdmin.fieldsGeneralGlt;
          }
          this.searchingTableInfo = false;
        }
      }
    },
    async reportQuoteForAutoquote() {
      if (this.filterInfo.startDate !== "" && this.filterInfo.endDate !== "") {
        this.searchingTableInfo = true;
        let info = {};
        info.initial_date = this.filterInfo.startDate;
        info.final_date = this.filterInfo.endDate;
        let response = await this.$store.dispatch(
          "adminPanelGeneral/getReportByAutoquote",
          info
        );
        if (response) {
          this.fieldsReport = this.optionsAdmin.fieldsAutoquote;
          this.itemsGeneral = response;
        }
        this.searchingTableInfo = false;
      }
    },
    async reportQuoteAvgTimePerQuote() {
      if (this.customerId !== "" && this.customerId !== null) {
        this.searching = true;
        this.showText = false;
        let info = {};
        info.custom_id = this.customerId;
        let response = await this.$store.dispatch(
          "adminPanelGeneral/getReportByAvgTimePerQuote",
          info
        );
        this.reportAvgTimePerQuote = response.avg_time_per_quote;
        this.showText = true;
        this.searching = false;
      }
    },
    async reportGeneralForClient() {
      if (this.filterInfo.startDate !== "" && this.filterInfo.endDate !== "") {
        this.searchingTableInfo = true;
        let info = {};
        info.initial_date = this.filterInfo.startDate;
        info.final_date = this.filterInfo.endDate;
        let response = await this.$store.dispatch(
          "adminPanelGeneral/getReportGeneralByClient",
          info
        );
        if (response) {
          this.fieldsReport = this.optionsAdmin.fieldsGeneralForClient;
          this.itemsGeneral = response;
        }
        this.searchingTableInfo = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
*,
*::before,
*::after {
  box-sizing: border-box;
}
::v-deep .admin-nav {
  margin: 16px 52px;
}
::v-deep .nav-tabs {
  width: 60vw;
}
.searchbar-wrapper {
  margin: 20px 30px;
}
.select-organization {
  margin-top: 7px;
}
.admin-panel-users-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.select-update {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 150px;
  margin: 10px;
  border: 1px solid #a3a3a3;
  border-radius: 6px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
  &__icon {
    height: 20px;
    margin: 5px 2px 5px 5px;
    color: #7c7c7c;
  }
  &__select {
    color: var(--text-color);
    border: none;
    background: none;
    font-weight: bold;
  }
}
.admin-panel-users {
  width: 95vw;
}
body {
  margin: 0;
}
.tai-img {
  margin: 20px;
}
::v-deep .view-wrapper .side-content {
  width: 85vw;
}
.wrapper {
  width: 100%;
  display: flex;
}
::v-deep .info-table-spinner {
  width: 100%;
}
.pane {
  color: black;
  min-width: 200px;
}
.left {
  width: 50%;
  min-width: 30%;
}
.left-spinner {
  left: 25%;
  background: #fafafa;
  opacity: 92%;
  width: 50%;
  padding: 20vh;
  height: 100vh;
}
.filter-options-client {
  position: relative;
  border-radius: 3px;
  margin: 20px 30px 0px 30px;
  display: flex;
  flex-direction: column;
  align-items: start;
  &__avg-time-per-quote {
    width: 100%;
  }
  &__custom-id {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 1.5rem 0;
    align-items: center;
  }
}
.not-found {
  position: absolute;
  bottom: 0.2rem;
  width: 60%;
  font-size: 14px;
  text-align: left;
  color: red;
}
.quotes-option {
  display: flex;
  justify-content: space-between;
}
.quotes-option-filter {
  @include option-filter;
}
.right-spinner {
  right: 25%;
  background: #fafafa;
  opacity: 92%;
  width: 90%;
  padding: 20vh;
  z-index: 4;
}
.right {
  width: 50%;
  min-width: 40%;
  position: relative;
}
.table-general {
  width: 100%;
  box-shadow: 1px 2px 10px 0px #888888;
  border-radius: 3px;
}
::v-deep .table-general tbody,
td,
tfoot,
th,
thead,
tr {
  cursor: pointer;
}
::v-deep .table-general .table-active {
  color: var(--app-font-color);
}
.user-name {
  font-size: 1.25rem;
  color: #00557e;
  text-align: right;
  margin: 5px 25px;
  font-weight: bold;
}
.graphic {
  width: 70%;
}
.graphicsSent {
  width: 70%;
}
::v-deep .btn-info {
  color: #ffffff;
  background-color: #47a3b9;
  border-radius: 15px;
  text-transform: capitalize;
  border: none;
  padding: 10px 10px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px 0px,
    rgba(0, 0, 0, 0.19) 0px 6px 20px 0px;
  &:enabled {
    transition: all 0.1s ease;

    &:hover {
      background-color: var(--button-hover-color);
      transform: scale(1.05);
    }
  }
}
.table-specific {
  margin: 15px 30px 30px 30px;
  box-shadow: 1px 2px 10px 0px #888888;
  border-radius: 3px;
}
.gutter {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5.5px;
  height: 100%;
  background: #f6f6f6;
  position: absolute;
  top: 0;
  left: 0;
  cursor: col-resize;
  z-index: 1;
}
.filter {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-around;
  box-shadow: 1px 1px 1px 2px #cdcdcd;
  border-radius: 3px;
  margin: 10px 30px 0px 30px;
}
.filter-place {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin: 10px 10px 5px 10px;
  height: 45px;
  border: 1px solid #a3a3a3;
  padding: 5px;
  border-radius: 6px;
  color: #a3a3a3;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px 0px,
    rgba(0, 0, 0, 0.19) 0px 6px 20px 0px;
  &__icon {
    height: 20px;
    margin: 5px;
    color: #7c7c7c;
  }
  &__select {
    color: var(--text-color);
    border: none;
    background: none;
    font-weight: bold;
  }
}
.quotes-datepicker {
  display: flex;
  background: #fafafa;
  margin: 10px;
  color: var(--app-font-color);
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  &__title {
    margin: 5px;
  }
  &__input {
    background-color: transparent;
    padding: 5px;
    border-left: 0px;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 1px solid var(--app-font-color);
    color: #a3a3a3;
  }
}
.gutter__block {
  width: 70%;
  height: 11px;
  background-color: #707070;
  border-radius: 8px;
}

@media (max-width: 800px) {
  .wrapper {
    width: 100%;
    flex-direction: column;
  }
  .left {
    width: 100%;
  }
  .right {
    width: 100%;
  }
  .gutter {
    display: none;
  }
  .admin-nav {
    margin: 10px;
  }
  .select-update {
    margin: 10px;
  }
  .admin-panel-users {
    width: 93vw;
  }
  .left-spinner {
    left: 50%;
    background: #ebecec;
    opacity: 92%;
    width: 100%;
    height: 100%;
    z-index: 4;
  }
  .right-spinner {
    display: none;
  }
}

@media (max-width: 520px) {
  .admin-panel-users-header {
    flex-wrap: wrap;
  }
}
</style>
