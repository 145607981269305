<template>
  <div class="filter-options" :key="renderFilter">
    <div class="quotes-option-filter">
      <select
        @change="selectReport($event)"
        class="quotes-option-filter__select"
      >
        <option value="0">Quotes</option>
        <option value="1">History</option>
      </select>
    </div>
    <div
      class="quotes-option-filter"
      :key="renderSpecificSelect"
      v-if="changeTable == false"
    >
      <span>
        <font-awesome-icon
          class="link-icon quotes-option-filter__icon"
          icon="filter"
        />
      </span>
      <select
        @change="selectQueue($event)"
        class="quotes-option-filter__select"
      >
        <option
          :key="index"
          :value="option.value"
          v-for="(option, index) in optionsAdmin.optionsQueue"
        >
          {{ option.text }}
        </option>
      </select>
    </div>
    <div
      class="quotes-option-filter"
      :key="renderSpecificSelect"
      v-if="changeTable == true"
    >
      <span>
        <font-awesome-icon
          class="link-icon quotes-option-filter__icon"
          icon="filter"
        />
      </span>
      <select
        @change="selectStatusHistory($event)"
        class="quotes-option-filter__select"
      >
        <option
          :key="index"
          :value="option.value"
          v-for="(option, index) in optionsAdmin.optionsHistory"
        >
          {{ option.text }}
        </option>
      </select>
    </div>
    <div class="quotes-datepicker">
      <div>
        <label class="quotes-datepicker__title" for="startDate"
          >Start date</label
        >
        <input
          class="quotes-datepicker__input"
          type="date"
          v-model="filterData.startDate"
          id="startDate"
          name="trip-start"
          required
        />
      </div>
      <div>
        <label class="quotes-datepicker__title" for="endDate">End date</label>
        <input
          class="quotes-datepicker__input"
          type="date"
          v-model="filterData.endDate"
          id="endDate"
          name="trip-start"
          required
        />
      </div>
    </div>
    <div class="quotes-datepicker">
      <div>
        <label class="quotes-datepicker__title" for="endDate">Start Time</label>
        <input
          class="quotes-datepicker__input"
          type="time"
          v-model="filterData.startTime"
          id="startTime"
          name="trip-start"
          required
        />
      </div>
      <div>
        <label class="quotes-datepicker__title" for="endDate">End Time</label>
        <input
          class="quotes-datepicker__input"
          type="time"
          v-model="filterData.endTime"
          id="endTime"
          name="trip-start"
          required
        />
      </div>
    </div>
  </div>
</template>
<script>
import optionsAdmin from "../utils/adminPanelOptions";
export default {
  name: "FilterOptions",
  props: ["changeUser", "updateSelected"],
  components: {},
  data() {
    return {
      optionsAdmin: optionsAdmin,
      filterData: {
        optionSelectReport: 0,
        startDate: "",
        endDate: "",
        optionSelected: [],
        optionStatusHistory: null,
        startTime: "",
        endTime: ""
      },
      renderSpecificSelect: 0,
      renderFilter: 0,
      values: ["6", "7", "8", "10"],
      changeTable: false,
      setInterval: null
    };
  },
  watch: {
    filterData: {
      deep: true,
      handler() {
        this.filterDataEmit();
      }
    },
    updateSelected(time) {
      time > 0 ? this.interval(time) : clearInterval(this.setInterval);
    },
    changeUser() {
      this.renderFilter += 1;
      this.filterData.startDate = "";
      this.filterData.endDate = "";
      this.filterData.startTime = "";
      this.filterData.endTime = "";
      this.filterData.optionSelectReport = "";
      this.filterData.optionStatusHistory = "";
      this.filterData.optionSelected = "";
      this.changeTable = false;
      (this.startTime = ""), (this.endTime = "");
    }
  },
  methods: {
    filterDataEmit() {
      this.$emit("filterData", this.filterData);
    },
    interval(time) {
      this.setInterval = setInterval(this.filterDataEmit, time);
    },
    selectReport(event) {
      this.filterData.optionSelectReport = event.target.value;
      if (this.filterData.optionSelectReport === 0) {
        this.changeTable = false;
        this.renderSpecificSelect += 1;
      } else {
        this.changeTable = true;
        this.renderSpecificSelect += 1;
      }
    },
    selectQueue(event) {
      let queueSelect = event.target.value;
      if (queueSelect === "All") {
        this.filterData.optionSelected = this.values;
      } else {
        this.filterData.optionSelected = [queueSelect];
      }
    },
    selectStatusHistory(event) {
      this.filterData.optionStatusHistory = event.target.value;
    }
  }
};
</script>
<style scoped lang="scss">
.filter-options {
  border-radius: 3px;
  box-shadow: 1px 1px 1px 2px #cdcdcd;
  margin: 10px 30px 0px 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
}
.quotes-option-filter {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 45px;
  border: 1px solid #a3a3a3;
  padding: 5px;
  border-radius: 6px;
  color: #a3a3a3;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px 0px,
    rgba(0, 0, 0, 0.19) 0px 6px 20px 0px;
  margin: 10px;
  &__icon {
    height: 20px;
    margin: 5px;
    color: #7c7c7c;
  }
  &__select {
    color: var(--text-color);
    border: none;
    background: none;
    font-weight: bold;
  }
}
.quotes-datepicker {
  background: #fafafa;
  margin: 10px;
  color: var(--app-font-color);
  &__title {
    margin: 5px;
  }
  &__input {
    background-color: transparent;
    padding: 5px;
    border-left: 0px;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 1px solid var(--app-font-color);
    color: #a3a3a3;
  }
}
</style>
